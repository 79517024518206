
article.platformNavigation {
  position: fixed;
  top: 0;
  text-align: center;
  z-index: 20;
  width: 100%;
  padding: 20px;
  background-color: $white;
  .logo {
    margin: 20px;
    @include breakpoint(small){
      float: left;
      margin: 0 0 -20px 0;
    }
  }
    .navLinks {
      display: inline;
      text-align: center;
      a.t2 {
        padding:15px;
        text-transform: uppercase;
        font: 16px/26px $primaryFont;
      }
      @include breakpoint(small) {
        display: none;
      }
    }
  .platform-search {
    display: inline-block;
    a {
      display: inline-block;
      font-family: Montserrat, Arial, sans-serif;
      color: #A8353A;
      font-size: 14px;
      color: #A8353A;
      padding: 0px 20px 0 20px;
    }
    a:hover {
      border-bottom: 0;
      color: #3A0312;
      text-decoration: none;
    }
  }
  .mobileMenu {
    display: none;
    float: right;
    color: $maroon;
    margin-top: 5px;
  }

  article.mobileNavigation {
    text-align: left;
    margin-top: 30px;
    padding: 0;
  }

}

@include breakpoint(small-medium) {
  article.platformNavigation {
    padding: 20px 0;
  }
}

@include breakpoint(small) {
  article.platformNavigation {
    height: 62px;
    padding: 20px;

    .logo img {
      height: auto;
      width: 150px;
    }

    .loginSearch {
      position: absolute;
      top: 20px;
      right: 0;
      padding-right: 30px;
      text-align: right;
    }

    .platform-search {
      display: inline-block;
      position: absolute;
      top: 20px;
      right: 30px;
      text-align: right;
      margin-top: 0px;
    }

    .mobileMenu {
      display: block;
      float: right;
      color: #A8353A;
      margin-top: 0px;
    }

    article.mobileNavigation {
      margin: 30px -20px 0;
      padding: 0 30px;
    }
  }
}
