article.textDoubleTriangleRight {
  background-color: $offWhite;
  overflow: hidden;
  position: relative;

  &#products {
      position: static; // Addipel page
  }

  .imageBox {
    position: absolute;
    left: 50%;
    bottom: 0px;
    width: 1243px;
    height: 1243px;
    @include breakpoint (small-medium){
        opacity: .4;
        display: none;
    }
    .imageMask {
      width: 1243px;
      height: 1243px;
      position: absolute;
      top: 0;
      left: 0;
    }

  }
  .standardTextBox {
    margin-top: 40px;
  }
  article.quoteBox {
    margin-top: 200px;
  }

  @include breakpoint(small) {
    height:auto;
    overflow: hidden;
    position: relative;

    margin-bottom: 0px;
    .imageBox {
      left: 0;
    }
    article.standardTextBox {
      margin-top: 50px;
      /*p.t2 {
          display: none;
        }*/
      .btn {
        margin-bottom: 20px;
        width: 100%;
      }
    }
    article.standardTextBox:last-of-type {
      margin-bottom: 300px;
      @include breakpoint (small) {
          margin-bottom: 20px;
      }
    }
    article.quoteBox {
      margin-top: 10px;
    }
  }

}