// Usage @include breakpoint(small) { /* Styles here */ }
@mixin breakpoint($point) {
	$small: 767px; // Up to 767px
	$medium: 1024px; // Up to 1024px
	@if $point == large {
		@media only screen and (min-width : $medium+1) { @content; }
	}
	@else if $point == medium-large {
		@media only screen and (min-width: $small+2) { @content; }
	}
	@else if $point == medium {
		@media only screen and (min-width: $small+1) and (max-width: $medium) { @content; }
	}
	@else if $point == small-medium {
		@media only screen and (max-width: $medium) { @content; }
	}
	@else if $point == small {
		@media only screen and (max-width: $small + 1) { @content; }
	}
}

@mixin layout-list {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		display: inline;
	}
}