article.logout {
  h2 {
    margin-bottom: 30px;
  }
  p.t2 {
    margin-bottom: 150px;
  }
  button {
    width: 100%;
    margin: 20px 0;
  }
  .register {
    text-align: center;
    margin-bottom: 30px;
  }
}
