article.user {
  &.formWrapper {
    h2 {
      margin-bottom: 50px;
    }
    p.t2 {
      margin: 30px 0;
    }
    a {
      display: inline-block;
      margin: 30px 0;
      &.btn {
          width: 100%;
      }
    }
    button {
      margin: 30px 0;
      &.btn {
        margin-right: 0;
        width: 96%;
      }
    }
    .subscribe {
      margin: 20px 0;
    }
    .reset {
      margin: 0;
    }
  }
}
