article.staffDetail {
.return {
  display: none;
}
  h1 {
    margin: 50px 0 20px 0;
  }
  img {
    margin: 20px 0;
    width: 100%;
    height: auto;
  }
  p.t2 {
    margin: 20px 0;
  }
  .contact {
    p.t2 {
      color: $maroon;
      i {
        margin-right: 10px;
      }
    }
  }
}
