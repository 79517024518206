article.listingNavigation {
  background-color: $white;
  padding: 50px 0;
  @include breakpoint(small){
    padding: 20px 0;
  }
  .navGroup {
    margin-top: 50px;
    text-align: center;
    .btn {
      display: inline-block;
      margin:20px;
      @include breakpoint(small-medium) {
          margin: 20px 10px;
          padding: 10px;
      }
      &.disabled {
        border-color: $gray;
        color: $gray;
      }
    }
    input {
      border: none;
      height: 40px;
      width: 50px;
      border-bottom: solid 1px black;
      text-align: center;
    }
    .pages {
      display: inline-block;
      .pageNumber {
        color: black;
        border-bottom: 1px solid black;
        padding-bottom: 10px;
      }
    }
  }
}
