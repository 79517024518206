article.productSearch {
  padding: 50px 0 150px 0;

  .search-query {
    background-color: transparent;
    border: none;
    width: 100%;
    font-size: 20px;
    padding: 5px 20px;

    margin-bottom: 0;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;

  }
  
  button {
    border: 0;
    background-color: transparent;
    /** belows styles are working good */
    padding: 2px 2px;
    margin-top: 0px;
    position: relative;
    right: 10px;
    top: 0px;
    margin-bottom: 0;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    font-size: 34px;
    color: $textGray;
  }
  input[type="text"] {
    &::placeholder {
      color: $lightGray;
    }
  }
  .searchContainer {
    background-color: $offWhite;
    position: relative;
    border-bottom: 2px solid $lightGray;
    .form-control {
    box-shadow: none;
    color: $textGray;
  }
  }
  .dropdownTypeAhead {
    display: none;
    width: 100%;
    height: 340px;
    position: absolute;
    background-color: $white;
    border: solid 2px $lightGray;
    top: 70px;
    padding: 20px 0;
    z-index: 200;
    overflow: auto;
    p.t2 {
      text-transform: uppercase;
      color: $textGray;
      margin-bottom: 0;
      line-height: 38px;
    }
    ul {
      margin-bottom: 0;
      padding-left: 20px;
      li {
        list-style: none;
        a {
          color: $textGray;
          vertical-align: top;
        }
        a:hover {
          color: $lightGray;
          text-decoration: none;
        }
      }
    }
  }
  span.grey {
    color: black;
    font-weight: 600;
  }
  @media (max-width: $medium) {
    .input-group {
      width: 100%;
    }
  }
}
