article.statsImage {
  padding: 60px 20px;
  @include breakpoint (small) {
    padding: 40px 20px;
  }
  .titleBox {
    position: absolute;
    top: -70px;
    background-color: $maroon;
    height: 360px;
    width: 360px;
    z-index: 1;
    @include breakpoint (small) {
      top: -80px;
      height: 200px;
      width: 240px;
  }
    h4 {
      text-transform: uppercase;
      color: $white;
      margin: 20px 35px;
      @include breakpoint (small) {
        margin: 0 10px;
      }
    }
    &.pullLeft {
      left: -20px;
      @include breakpoint (small) {
        left: -10px;
      }
    }
    &.pullRight {
      right: -20px;
      @include breakpoint (small) {
        right: -10px;
      }
    }
  }

  .imageContainer {
    display: inline-block;
    position: relative;
    z-index: 2;
    width: 100%;
    padding-top: 56.25%;
    background-size: cover;
  }
}
