
article.textBoxTriangleLeft {
  width: 100%;
  height: 600px;
  background-color: $white;

  .imageBox {
    position: absolute;
    right: 50%;
    top: 40px;
    width: 999px;
    height: 550px;
    margin-top: 40px;
    z-index: 2;
    @include breakpoint(small){
        opacity: .4;
        margin-top: 0;
    }
  }

  div.marginContainer {
    position: relative;
    z-index: 2;
  }

  article.standardTextBox {
    position: relative;
    margin-top: 100px;

    button {
      margin-top: 50px;
    }
  }



}
@media only screen and (max-width: 767px){
    article.textBoxTriangleLeft {
    height:auto;
    overflow: hidden;
    position: relative;

    margin-bottom: 0px;
    .imageBox {
      height: 330px;
      width: 600px;
      left: 50%;
      right: auto;
      top: 0;
    }
    article.standardTextBox {
      margin-top: 100px;
      p.t3 {
        width: 50%;
      }
      h2 {
        width: 50%;
      }
      .btn {
        margin-bottom: 20px;
        width: 100%;
      }
    }
    }
}