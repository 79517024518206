article.staff {
  background-color: $white;
  width: 100%;
  .staffProfile {
    margin-bottom: 80px;
    text-align: center;
    img {
      width: 100%;
      max-width: 300px;
      height: auto;
    }
  }
  h1 {
    margin-bottom: 50px;
    @include breakpoint(small){
      text-align: center;
    }
  }
  .t3 {
    color: $maroon;
    margin-top: 20px;
  }
  a.t4 {
    color: $maroon;
  }
}
