article.addipel {
  article.header {
    position: fixed;
    top: 0;
    text-align: center;
    z-index: 9999;
    width: 100%;
    background-color: $white;
    img {
      float: left;
      margin-top: 20px;
      @include breakpoint(small-medium) {
        margin-top: 10px;
        max-width: 120px;
        width: 100%;
        height:auto;
      }
    }
  }
  .anchor {
    padding-top: 80px;
    @include breakpoint(small-medium) {
      padding-top: 50px;
    }
  }
  article.background {
    overflow: visible;
    position: absolute;
    top: 0;
    height: 3000px;
    width: 100%;
  }
  .pageBody {
    background-color: transparent;
  }
  article.backgroundSlashLeft {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .mask {
      width: 2400px;
      height: 2300px;
      overflow: visible;
    }
  }
  article.backgroundSlashRight {
    position: absolute;
    background-color: transparent;
    top: 2300px;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .mask {
      width: 2400px;
      height: 2200px;
      overflow: visible;
    }
  }
  article.topHeroBanner {
    margin-top: 100px;
    .textCol {
        background: #f7f6f6;
    }
    .heroBannerText {
      margin-top: 100px;
    }
    @include breakpoint (small-medium) {
      margin-top: 0px;
      margin-bottom: 0;
      .heroBannerText {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  article.calloutBox {
    background-color: $textGray;
    margin-bottom: 100px;
    padding: 30px 80px;
  }
  .capabilities {
    @include breakpoint(small-medium) {
      margin-bottom: 0;
    }
    article.standardTextBox {
      margin-top: 0;
    }
    ul {
      padding-left: 20px;
      font-size: 16px;
      font: $secondaryFont;
      color: $textGray;
      li {
        margin-bottom: 20px;
      }
    }
  }
  article.textBoxTriangleLeft {
    height: auto;
    margin-bottom: 50px;
    article.standardTextBox {
      @include breakpoint(small-medium) {
        margin-top: 30px;
      }
    }

    .history {
      margin-top: 200px;
      article.standardTextBox {
        margin-top: 20px;
      }
      @include breakpoint(small-medium) {
        margin-top: 0;
      }
    }
  }
  article.statsImage {
    &.top {
      margin-top: -200px;
      @include breakpoint(small-medium) {
        margin-top: -100px;
        margin-bottom: 50px;
      }
    }
    .title {
      margin-bottom: 100px;
    }
    .titleBox {
      background-color: $textGray;
    }
  }
  article.formWrapper {
    button {
      width: auto;
    }
  }
  article.textDoubleTriangleRight {
    .imageBox {
      @include breakpoint(small-medium) {
        left: 50%;
      }
    }
    article.standardTextBox {
      @include breakpoint(small-medium) {
        margin: 0;
      }
    }
  }
  article.footer {
    padding: 20px;
    .smallPrint {
      text-align: left;
      @include breakpoint(small) {
        text-align: center;
      }
    }
    .socialMedia {
      margin-top: 10px;
      text-align: right;
      @include breakpoint(small) {
        text-align: center;
      }
    }
  }
}
