article.image {
  .left {
      float: left;
  }
  .right {
      float: right;
  }
  .center {
      display: block;
      margin: auto;
  }
}
