article.press {
  margin-bottom: 50px;
  article.topHeroBanner {
    .imageBox {
    opacity: .3;
    }
    margin-bottom: -200px;
  }
  .pressListing {
    width: 100%;
    height: auto;
    position: relative;
  }
  @media (max-width: $medium) {
    article.topHeroBanner {
      margin-bottom: 0px
    }

  }
}
