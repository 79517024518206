article.footer {
  padding: 70px 10px 30px 10px;
  background-color: $darkGray;
  color: $white;

  .contactInfo {
    line-height: 28px;

    img {
      margin-bottom: 20px;
    }

    p {
      color: $white;
    }
  }

  .socialMedia {
    margin-top: 50px;

    a {
      display: inline-block;
      color: $white;

      i {
        padding-right: 20px;
      }
    }
  }

  p {
    color: $white;
  }

  a {
    color: $white;
    display: block;
  }

  a.l1 {
    margin-bottom: 25px;
  }

  .l2 {
    margin-bottom: 40px;
  }

  .smallPrint {
    margin-top: 20px;
    position: relative;
    text-align: right;

    @include breakpoint(small-medium) {
      text-align: left;
    }

    span {
      color: $white;
    }

    a {
      display: inline-block;
      color: $white;
      padding-right: 20px;
    }
  }
}
