article.contactMap {
  article.calloutBox {
    margin: 0!important;
    height: 400px;
    button {
      margin-bottom: 50px;
    }
    i {
      margin-right: 15px;
      vertical-align: middle;
    }
    a.t2 {
        color: $white;
        &:hover {
            color: $hoverGray;
            @include breakpoint(small){
                color: $white;
            }
        }
        &:focus {
            color: $white;
            text-decoration: none;
        }
    }
  }
  .col-md-4 {
    padding-right: 0;
    @include breakpoint (small-medium) {
      padding-right: 15px;
    }
  }
  .col-md-8 {
    padding-left: 0;
    @include breakpoint (small-medium) {
      padding-left: 15px;
    }
  }
}
