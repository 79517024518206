article.calloutBox {
  padding: 30px 50px 30px 50px;
  margin: 20px 0;
  background-color: $maroon;
  h2, h4 {
    color: $white;
    text-transform: uppercase;
  }
  p.t2 {
    color: $white;
  }
  .btn {
    margin: 20px 0;
    color: $white;
    border-color: $white;
  }
}
