article.companyLinks {
  background-color: $sky;
  padding-top: 50px;
  padding-bottom: 50px;
  .maroonGroup {
    text-align: center;
    margin-bottom: 50px;
    .t2 {
      margin-top: 20px;
      color: $textGray;
    }
    a {
      color: $textGray;
      padding-left: 20px;
      @include breakpoint(small){
          display: block;
      }
    }
  }
  .middleSeperator {
    text-align: center;

    margin-top: 20px;
    .t4 {
      color: $textGray;
      text-transform: uppercase;
    }
  }
  .line {
    border-bottom: 1px solid $textGray;
    position: relative;

    margin-top: 20px;
    bottom: -10px;
  }
  .regionalCompanies {
      .col-xs-3 {
          @include breakpoint (small){
              padding: 0 5px;
          }
      }
  }
  .regionalCompanies a {
      @include breakpoint (small) {
          position: relative;
      }

     img.bottom {
       position: relative;
       width: 100%;
       height: auto;
       opacity: 0;
    }
    &:hover {
      img.bottom {
        opacity: 100;
      }
    }

    img.top {
      position: absolute;
      width: 100%;
      height: auto;
      left: 0;
      top: 0;
      padding: 0 15px;
      @include breakpoint(small-medium) {
        padding: 0;
      }

      &:hover {
        opacity: 0;
      }
    }
  }

}
