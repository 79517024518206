h1 {
    font: 25px/28px $primaryFont;
    color: $textGray;
    @media screen and (min-width: $medium) {
        font: 50px/55px $primaryFont;
        letter-spacing: -1px;
        font-weight: 500;
    }
}

h2 {
    font: 25px/30px $primaryFont;
    color: $textGray;
    font-weight: 600;
    &.section {
      font: 16px/20px $primaryFont;
      text-transform: uppercase;
    }
    @media screen and (min-width: $medium) {
      font: 34px/40px $primaryFont;
      font-weight: 600;
      &.section {
        font: 16px/20px $primaryFont;
        font-weight: 600;
      }
    }
}

h3 {
    font: 28px/35px $primaryFont;
    color: $textGray;
}
h4 {
  font: 20px/40px $primaryFont;
  color: $textGray;
  font-weight: 600;
  text-transform: uppercase;
}

p {
    font: 16px/26px $secondaryFont;
    color: $textGray;
}

.t1 {
    font: 18px/30px $secondaryFont;
    color: $textGray;
}

.t2 {
    font: 16px/26px $secondaryFont;
    color: $textGray;
}

.t3 {
    font: 700 16px/26px $secondaryFont;
    color: $textGray;
    text-transform: uppercase;
}

.t4 {
    font: 13px $secondaryFont;
    color: $textGray;
}

.l1 {
    font: 18px $primaryFont;
    color: $textGray;
}

.l2 {
    font: 700 18px $primaryFont;
    color: $textGray;
}

a {
    &:hover {
        cursor: pointer;
    }
    &.t2 {
        line-height: 38px;
        color: $maroon;
        &:hover {
            color: $darkMaroon;
        }
    }
}
