.btn {
  font: 14px $primaryFont;
  text-transform: uppercase;
  background-color: transparent;
  padding: 15px 30px;
  border-radius: 0;
  border-width: 2px;
  @include breakpoint (small-medium) {
      padding: 15px 5px;
  }
  &.btn-maroon {
    color: $maroon;
    border-color: $maroon;
    &:hover {
      color: $darkMaroon;
      border-color: $darkMaroon;
      @include breakpoint(small){
          color: $maroon;
          border-color: $maroon;
      }
    }
    &:focus {
      color: $maroon;
      border-color: $maroon;
    }
    &:active {
      color: $maroon;
      border-color: $maroon;
    }
  }
  &.btn-white {
    color: $white;
    border-color: $white;
    &:hover {
      color: $hoverGray;
      border-color: $hoverGray;
      @include breakpoint(small){
          color: $white;
          border-color: $white;
      }
    }
    &:focus {
      color: $white;
      border-color: $white;
    }
  }
  &.btn-gray {
    color: $textGray;
    border-color: $textGray;
    &:hover {
      color: $hoverGray;
      border-color: $hoverGray;
      @include breakpoint(small){
          color: $textGray;
          border-color: $textGray;
      }
    }
    &:focus {
      color: $textGray;
      border-color: $textGray;
    }
  }
}

.form-group {
  margin-bottom: 0;
}
.form-control {
  border-radius: 0;
  padding: 10px 12px;
  height: 60px;
    color: $textGray;
  font-size: 18px;
}


textarea.form-control {
  height: auto;
}

article.formWrapper {
  margin: 50px 0;
  h1 {
    margin-bottom: 50px;
  }
  h4 {
    margin: 40px 0 20px 0;
  }
  p.t2 {
    margin-bottom: 0;
  }
  .account {
    margin: 30px 0;
  }
  .col-md-4, .col-md-2, .col-md-3, .col-md-5, .col-md-6, .col-md-8, .col-md-10, .col-md-12 {
    padding: 0;
  }
  .row {
    padding: 0;
    margin: 0;
  }
  .subscribe {
    margin: 20px 0;
  }
  button.btn {
    margin-right: 20px;
    width: auto;
  }
  select {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    -moz-appearance: none;
    background: #fff url('../images/downArrow.png') no-repeat;
    background-size: 15px;
    background-position:  right 15px center;
    color:#999999;
  }
}
