article.heroBannerHome {
  position: relative;
  overflow: hidden;

  div.slashContainer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    
    .mask {
      position: relative;
      height: 100%;
      left: 35%;
      // specify width in IE 10 and 11 only
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 6900px!important;
        left: 0!important;
}
    }

  }
  .topHeroBanner {
    position: relative;
    z-index: 1;
    @include breakpoint(medium-large) {
        min-height: 600px;
    }
    .imageBox {
      opacity: 1;
    }
    .heroBannerText {
        margin: 50px 0;
    }
  }

  @include breakpoint(small-medium) {
    overflow: hidden;
    margin-bottom: 0px;
    div.slashContainer {
      display: none;
    }
  }

  article.bottomHeroBanner {
    .stats {
      h2,
      .t2 {
        background-color: #333333;
        color: $white;
        @include breakpoint (small) {
            background-color: transparent;
            color: #333333;
        }
      }
    }
  }

}
