article.sampleRequestConfirmation {
  margin: 50px 0;
  h1 {
    margin-bottom: 50px;
  }
  p.t2 {
    margin-bottom: 0;
  }
  .thankYou {
    margin: 20px 0;
  }
  i {
    margin-right: 10px;
  }
  .btn {
    margin-top: 30px;
  }
}
