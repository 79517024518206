body {
  color: $textGray;
  background-color: $sky;
}
a:focus {
    outline:0;
    text-decoration: none;
}

.demoContainer {
  text-align: left;
  border-bottom: 1px solid #e5e5e5;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 48px;
  padding-bottom: 48px;
  border-radius: 6px;
  color: inherit;
  background-color: #eeeeee;
}

.demoHeader {
  margin-bottom: 20px;
}

.container {
  height: 100%;
  position: relative;
  .container {
      padding: 0;
  }
}
.sitecoreHeaderMargin {
    position: relative!important;
}
.pageBody {
  background-color: $offWhite;
  width: 100%;
  position: relative;
  overflow: hidden;
  @include breakpoint(small) {
    margin-top: 62px;
  }
}
.bodyContainer {
  height: 100px;
  background-color: $sky;
  margin: 20px 0;
}

.clearfix {
  *zoom: 1;
  &:before, &:after {
    display: table;
    content: "";
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}

.marginContainer {
  @extend .clearfix;
}

.modal-xl {
  width: 100%;
  height: 100%;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: $medium) {
  .container {
    max-width: 1600px;
  }

  /* Remove the padding we set earlier */
  .header{
    padding-left: 0;
    padding-right: 0;
  }

  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }

}
@media screen and (max-width: 950px){
    .pageBody {
        margin-top: 62px;
    }
}
@media (max-width: $large) {
  .container {
    margin: 0;
    width: 100%;
  }
  .marginContainer {
    width: 100%;
  }

  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    padding-left: 0!important;
    padding-right: 0!important;
  }
}
@media (min-width: $large) {
  .container {
    width: 100%;
  }
  .marginContainer {
    margin: 0 213px;
    position: relative;
    .marginContainer {
        margin: 0;
    }
  }
}
@media (max-width: $xlarge){
  .marginContainer {
    margin: 0;
    padding: 0 20px;
  }
}
