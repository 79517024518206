article.reportBox {
  padding: 20px;
  margin: 20px 0;
  background-color: $maroon;
  h4 {
    color: $white;
    text-transform: uppercase;
  }
  p.t2 {
    color: $white;
  }
  .btn {
    margin: 10px 0;
  }
}
