article.heroBanner {
width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  .imageBox {
  position: absolute;
  left: 450px;
  top: 0;
  width: 1223px;
  height: 816px;
  .imageMask1 {
    width: 1223px;
    height: 816px;
    position: absolute;
    top: 0;
    left: 0;
  }
    .imageMask2 {
      width: 1223px;
      height: 816px;
      position: absolute;
      top: 0;
      left: 0;
    }
}
  opacity: .5;
}
