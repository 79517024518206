article.standardCard {
  background-color: $white;
  padding: 40px 20px;
  margin: 20px 10px;
  border: 1px solid $lightGray;
  position: relative;
  .featureText {
    display: none;
  }
  &.featured {
    .featureText {
      display: block;
      color: $white;
      text-transform: uppercase;
      position: relative;
      top: 0;
      margin-top: -40px;
      background-color: $blueMetal;
      width: 120px;
      text-align: center;
      h2.section {
        margin: 0;
        padding: 10px;
        color: $white;
      }

    }
    border: 3px solid $blueMetal;
  }
  a.t2 {
    text-transform: uppercase;
  }
  .sectionLine {
    margin-top: 20px;
  }
  h3 {
    margin-top: 20px;
  }
  @media  (max-width: 991px){
    margin: 20px 0;
    &.featured {
      .featureText {
        left: -20px;
      }
      border: 3px solid $blueMetal;
    }
  }
}
