article.productCard {
  background-color: $white;
  padding: 40px 20px;
  margin: 20px 10px;
  border: 1px solid $lightGray;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  .featureText {
    display: none;
  }
  &.featured {
    .featureText {
      display: block;
      color: $white;
      text-transform: uppercase;
      position: relative;
      top: 0;
      margin-top: -40px;
      background-color: $blueMetal;
      width: 120px;
      text-align: center;
      h2.section {
        margin: 0;
        padding: 10px;
        color: $white;
      }

    }
    border: 3px solid $blueMetal;
  }
  .sectionLine {
    margin-top: 20px;
  }
  h3 {
    margin-top: 10px;
  }
  .buttonGroup {
    .btn {
      position: relative;
      display: inline-block;
    }
    .btn:before {
      position: absolute;
      left: 10px;
      font-family: "FontAwesome";
      content: attr(data-background-icon);
    }
  }
  .btn {
    display: block;
    margin-right: 20px;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    &.disabled {
      border-color: $lightGray;
      color: $lightGray;
      background: linear-gradient(to bottom right, transparent, transparent 49%, $lightGray 49%, $lightGray 51%, transparent 51%, transparent);
    }
  }
  .specBlock {
    display: inline-block;
    background-color: $sky;
    padding: 2px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    p {
      margin-bottom: 0;
    }
  }
  @media  (max-width: 991px){
    margin: 20px 0;
    .request {
      width: 100%;
    }
    &.featured {
      .featureText {
        left: -20px;
      }
      border: 3px solid $blueMetal;
    }
  }

}

article.companyCard {
  background-color: $white;
  padding: 40px 20px;
  margin: 20px 0px;
  border: 1px solid $lightGray;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}
