article.topHeroBanner {
  width: 100%;

  @include breakpoint(small) {
    min-height: auto;
    margin-bottom: 60px;
  }

  .imageBox {
    position: absolute;
    left: 40%;
    top: 0;
    width: 1223px;
    height: 816px;
    opacity: .5;
    image {
        width: 1248px;
        height: 745px;
        background-size: cover;
    }
  }
  .textCol {
      background: #f7f6f6;
  }
  .heroBannerText {
    margin: 100px 0;
    @include breakpoint(small-medium) {
        width: 100%;
    }
    @include breakpoint(small) {
      width: 100%;
      margin: 100px 0 20px 0;
    }
    h1 {
      margin: 0 0 20px 0;
    }
    @include breakpoint(small) {
      button {
        margin-top: 50px;
      }
    }
    .btn {
      margin: 20px 0;
    }
  }
  article.standardTextBox {
    margin: 50px 0;
    @include breakpoint(small) {
      margin: 20px 0;
    }
  }
}
