.platformSearchPage article.productFilter {
  margin-right: 0;
}
article.productFilter {
  margin-right: 20px;
  .title {
    font-family: $primaryFont;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }
  .input-group {
    width: 100%;
    .form-control {
      box-shadow: none;
    }
    .search-query {
      background-color: transparent;
      border: none;
      width: 100%;
      border-bottom: 2px solid $lightGray;
      font-size: 20px;
      padding: 5px 0px;
    ;
      /* IE7-8 doesn't have border-radius, so don't indent the padding */

      margin-bottom: 0;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;

    }
    button {
      z-index: 999;
      border: 0;
      background: none;
      /** belows styles are working good */
      padding: 2px 2px;
      margin-top: 0px;
      position: absolute;
      right: 20px;
      top: 15px;
      /* IE7-8 doesn't have border-radius, so don't indent the padding */
      margin-bottom: 0;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      font-size: 20px;
      color: $textGray;
    }
    input[type="text"] {
      color: $textGray;
      &::placeholder,
      ::-webkit-input-placeholder,
      ::-moz-placeholder,
      :-ms-input-placeholder {
        color: #999;
      }
    }
  }
  .input-group-btn {
      z-index: 3;
      position: absolute;
      right: 0;
  }
  .row {
    vertical-align: middle;
  }
  .filterGroup {
    margin-top: 50px;
    .rightCol {
      text-align: right;
      padding-right: 20px;
      vertical-align: middle;
      a {
        color: $textGray;
        i {
          margin-top: 5px;
        }
      }
      a:hover {
        text-decoration: none;
      }
      a:focus {
        text-decoration: none;
      }
      p.t2 {
        margin-bottom: 0;
      }
    }
    .detailRow {
      margin-top: 10px;
      label {
        font: 16px/26px "Open Sans", Arial, sans-serif;
        color: #333333;
        padding-right: 20px;
      }
      .checkbox {
        margin-top: 0;
      }
    }
    .more {
      padding-left: 20px;
      a {
        color: $textGray;
        font-size: 16px;
        i {
          padding-right: 10px;
        }
      }
    }
    #additionalSuppliers {
      display: none;
    }
  }
  @include breakpoint(small) {
    .view {
      display: none;
      &.open {
        display: block;
      }
    }
    .filter-btn {
        width: 100%;
    }
  }
  @include breakpoint(medium-large) {
      .filter-btn {
          display: none;
      }
  }
}
