article.supplierListing {
  position: relative;
  z-index: 300;
  .logos {
      background-color: $white;
  }
  .logoContainer {
    background-size: contain!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
    height: 150px;
    width: 150px;
    margin-bottom: 20px;
    @include breakpoint(small){
      height: 80px;
      width: 80px;
    }
  }
  article.calloutBox {
    margin: 0;
  }
}
