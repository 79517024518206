article.detailNavigation {
  margin: 50px 0;
  float: left;
  width: 100%;
  position: relative;
  z-index: 200;
    @include breakpoint(small) {
      display: none;
    }
  .return {
    float: left;
    a {
      text-transform: uppercase;
    }
  }
  .navigationLinks {
    float: right;
    .btn {
      padding: 15px;
      margin-left: -5px;
      &.disabled {
        border-color: $gray;
        color: $gray;
      }
    }
    .pageCount {
      display: inline-block;
      margin-right: 20px;
      span.total {
        color: $lightGray;
      }
    }
    @include breakpoint(small) {
      display: none;
    }
  }
}
