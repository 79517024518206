article.productListing {
    .specs {
        display: block;
        padding-left: 20px;

        .specBlock {
            display: inline-block;
            background-color: $sky;
            padding: 2px 10px;
            margin-right: 10px;
            margin-bottom: 20px;

            p {
                margin-bottom: 0;
            }

            p {
                a {
                    color: $textGray;
                    padding-left: 10px;
                }
            }
        }
    }

    .topBar {
        display: block;
        margin-bottom: 20px;
        padding-left: 20px;

        .dropdownMenu {
            display: none;
            background-color: $white;
            border: solid 2px rgba(0, 0, 0, 0.2);
            position: absolute;
            top: 35px;
            padding: 20px;
            z-index: 999;

            ul {
                padding-left: 0;

                li {
                    list-style: none;

                    a {
                        color: $textGray;
                    }

                    a:hover {
                        color: $textGray;
                        text-decoration: none;
                    }

                    a:after {
                        border: none;
                    }
                }
            }
        }

        .dropdownMenu:before {
            position: absolute;
            top: -8px;
            left: calc(50% - 8px);
            display: inline-block;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #ffffff;
            border-left: 8px solid transparent;
            border-bottom-color: rgba(0, 0, 0, 0.2);
            content: '';
        }

        .dropdownMenu:after {
            position: absolute;
            top: -6px;
            left: calc(50% - 6px);
            display: inline-block;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #ffffff;
            border-left: 6px solid transparent;
            content: '';
        }

        .dropdownFilter {
            display: inline-block;
            background: #fff;
        }

        .dropdownFilter:hover {
            .dropdownMenu {
                display: block;
            }
        }

        .results {
            font-family: $primaryFont;
            font-weight: 600;
            font-size: 20px;
            text-transform: uppercase;
            padding-right: 20px;
            display: inline;
        }

        .sorting {
            display: inline;

            a {
                padding-right: 15px;
                padding-left: 10px;
                position: relative;
            }

            a:after {
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                content: "";
                display: inline-block;
                position: relative;
                top: -4px;
                right: -5px;
                width: 8px;
                height: 8px;
                margin-left: 5px;
                border: solid #A8353A;
                border-width: 0 1px 1px 0;
            }
        }
    }

    .productList {
        display: flex;
        flex-flow: row wrap;
        @include breakpoint(small) {
            display: block;
        }
        .productCard {
            flex-grow: 1;
            flex-shrink: 1;
            flex: 1;
            flex-basis: 40%;
        }
        .companyCard {
          flex-grow: 1;
            flex-shrink: 1;
            flex: 1;
            flex-basis: 100%;
        }
    }

    @media (max-width: 992px) {
        .specs {
            padding-left: 0px;
            margin-top: 20px;

            .specBlock {
                padding-left: 0;
            }
        }
    }
}
