article.text {
  position: relative;
  margin: 20px 0 100px 0;
  @include breakpoint(small){
    margin: 20px;
  }
  ul {
      padding-left: 20px;
      font-size: 16px;
      font: $secondaryFont;
      color: $textGray;
      li {
        margin-bottom: 20px;
      }
    }
}
