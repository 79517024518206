
article.header {
  background-color: $white;
  height: 95px;
  position: relative;
  margin-bottom: 0;

  .marginContainer {
    /* border-bottom: 1px solid #cccccc; */
    height: 100%;

    @include breakpoint(small-medium) {
      padding: 0 15px;
    }
  }

  .logoimage {
    max-height: 120px;
    height: 100%;
    display: flex;
    align-items: center;

    img {
      max-width: 270px;
      height: auto;
      width: auto;
      max-height: 120px;
    }
  }


  .loginSearch {
    position: absolute;
    top: 20px;
    right: 20px;

    span {
      font-family: Montserrat, Arial, sans-serif;
      font-size: 13px;
    }

    a {
      font-family: Montserrat, Arial, sans-serif;
      font-size: 11px;
      color: $maroon;
      padding-left: 30px;
    }

    .mobileMenu {
      display: none;

      a {
        color: $maroon;
        font-size: 18px;
      }
    }

    .signIn {
      i {
        margin-left: 5px;
      }

      &.active {
        display: inline-block;
      }

      &.inactive {
        display: none;
      }
    }
  }

  ul.navigationLinks {
    @include layout-list;
    position: absolute;
    bottom: 0;
    right: 20px;
    font-size: 14px;

    a {
      display: inline-block;
      font-family: Montserrat, Arial, sans-serif;
      color: $maroon;
      font-size: 14px;
      color: $maroon;
      padding: 0px 10px 20px 10px;
      margin-top: 20px;

      &.active {
        color: $darkMaroon;
        text-decoration: none;
        padding-bottom: 18px;
        border-bottom: 2px solid $darkMaroon;
      }

      &:focus {
        text-decoration: none;
      }
    }

    a:hover {
      color: $darkMaroon;
      text-decoration: none;
      padding-bottom: 18px;
      border-bottom: 2px solid $darkMaroon;
    }

    li:last-child a {
      padding-right: 0;
    }

    li.search {
      a:hover {
        padding-bottom: 20px;
        border-bottom: 0;
      }
    }
  }
}

article.mobileNavigation {
  display: none;
  background-color: $white;
  padding: 0 20px 20px 20px;
  .signIn {
    background-color: $white;
  }
  ul.mobileNavigationLinks {
    @include layout-list;
    li {
      display: block;
      padding: 0 15px;
    }
    a {
      display: block;
      padding: 15px 0;
      font-size: 16px;
      color: $maroon;
      text-transform: uppercase;
    }
  }
  .btn {
    display: block;
    width: 100%;
  }
}

@media only screen and (min-width: 1100px) {
  article.header ul.navigationLinks a {
    padding: 0px 20px 20px 20px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1530px) {
  article.header ul.navigationLinks a {
    padding: 0px 10px 20px 10px;
  }
}

@media only screen and (max-width: 950px) {

  article.header {
    height: 62px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    z-index: 203;

    .logoimage {
      max-height: 100px;
      height: 100%;
      display: flex;
      align-items: center;

      img {
        max-width: 180px;
        height: auto;
        width: auto;
        max-height: 100px;
      }
    }

    .loginSearch {
      position: absolute;
      top: 20px;
      right: 0;
      padding-right: 30px;
      text-align: right;

      #controllerHeaderLinks {
        display: inline-block;
      }

      span {
        font-family: Montserrat, Arial, sans-serif;
        font-size: 16px;
        color: $maroon;
      }

      a {
        font-family: Montserrat, Arial, sans-serif;
        font-size: 16px;
        color: $maroon;
        padding-left: 30px;
      }

      .signIn {
        display: none;

        &.active {
          display: none;
        }
      }

      .mobileMenu {
        display: inline-block;
        margin: 0 0 0 10px;
        padding-left: 0;
      }
    }

    .navigationLinks {
      display: none;
    }

    .userName {
      &.active {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 460px) {

  article.header {

    .logoimage {

      img {
        max-width: 130px;
        height: auto;
        width: auto;
        max-height: 62px;
        position: relative;
        top: 2px;
      }
    }


    .marginContainer {
      padding: 0;
    }

    .loginSearch {
      padding-right: 20px;

      span {
        font-size: 12px;
      }
    }
  }
}

/*@media only screen and (max-width: 370px) {

  article.header {
    img {
      height: auto;
      width: 130px;
    }

    
  }
}*/
