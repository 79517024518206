article.standardTextBox, article.standardTextImageBox {
  position: relative;
  margin-bottom: 50px;
  @include breakpoint (small-medium) {
    margin-bottom: 0;
  }
  button {
    margin-top: 30px;
  }
  .imageBox {
    margin: 30px 0;
    width: 50%;
    padding-top: 27.6%;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
  }

}
.sectionLine {
  border-bottom: 2px solid #ffd842;
  width: 30px;
  margin-bottom: 5px;
}

article.columns {
    article.standardTextBox, article.text {
        .container {
            padding: 0;
            .marginContainer {
                margin: 0;
            }
        }
    }
}
