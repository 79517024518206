article.productDetails {
background-color: $offWhite;
  position: relative;
  overflow: hidden;
.backgroundSlash {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: -60px;
  width: 100%;
  height: 100%;
  overflow: visible;
  .mask {
    width: 2400px;
    height: 2000px;
    overflow: visible;
  }
}
  .supplierLogo {
    margin: 0;
  }
  .helpBox {
    background-color: $maroon;
    width: 100%;
    height: 100%;
    color: $white;
    text-align: center;
    h4 {
      padding: 20px;
      color: $white;
    }
    .btn {
      width: 90%;
      margin: 0px 20px 20px 20px;
    }
  }
  .productDetail {
    margin-top: 60px;
    .btn {
      margin-right: 40px;
      margin-top: 50px;
      padding: 15px 50px;
      position: relative;
      @include breakpoint(small-medium){
          width: 100%;
          margin-top: 25px;
      }
    }
    .btn:before {
      position: absolute;
      left: 10px;
      font-family: "FontAwesome";
      content: attr(data-background-icon);
    }
  }
  .relatedProducts {
    padding: 0 20px;
    .headline {
      margin-top: 100px;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .specs {
    margin: 50px 0;
    .specRow {
      margin-bottom: 20px;
      h5 {
        text-transform: uppercase;
        font-weight: 600;
      }
      i {
        padding-left: 10px;
      }
      a {
        padding-left: 10px;
      }
      .specBlock {
        display: inline-block;
        background-color: $sky;
        padding: 2px 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        p {
          margin-bottom: 0;
        }
      }
    }
  }
  .supportingDocs {
    margin: 50px 0;
    .btn {
      width: 90%;
      margin: 20px 0;
      position: relative;
    }
    .btn:before {
      position: absolute;
      left: 10px;
      font-family: "FontAwesome";
      content: attr(data-background-icon);
    }
  }
  @media (max-width: $medium){
    margin-top: 50px;
  }
  @media (max-width: 992px) {
    .backgroundSlash {
      right: 0;
      left: auto;
    }
    h3 {
      font-size: 16px;
      margin: 0;
      font-weight: 600;
    }
    .supplierLogo {
      display: none;
    }
    .productDetail {
      margin-top: 20px;
    }
    .relatedProducts {
      padding: 0;
      margin-bottom: 30px;
      .headline {
        margin-top: 50px;
      }
    }
    .specs {
      margin: 20px 0;
      .specRow {
        .row {
          margin: 0;
        }
      }
      .specBlock {
        padding: 2px 0!important;
        margin-bottom: 10px;
      }
      a {
        padding-left: 0!important;
      }
    }
    .helpBox {
      .btn {
        margin: 0 0 20px 0;
      }
    }
    .supportingDocs {
      margin-top: 0;
      margin-bottom: 10px;
      .btn {
        width: 100%;
        margin: 20px 0;
        white-space: normal;
      }
    }

  }
}
