article.textBoxTriangleRight {
  width: 100%;
  background-color: $white;
  padding-top: 50px;

  .imageBox {
    position: absolute;
    right: -20%;
    top: -120px;
    width: 999px;
    height: 550px;
    margin-top: 40px;
    z-index: 2;
    @include breakpoint (small){
        opacity: .4;
    }
  }

  div.marginContainer {
    position: relative;
    z-index: 2;
  }

  @include breakpoint(small-medium) {

    height:auto;
    overflow: hidden;
    position: relative;
    margin-bottom: 0px;
    .imageBox {
      height: 330px;
      width: 600px;
      left: 50%;
      right: auto;
      top: 0;
      margin-top: 0;
    }
    article.standardTextBox {
      margin-top: 0;
      p.t3 {
        width: 50%;
      }
      h2 {
        width: 50%;
      }
      .btn {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }
}
.careers { //quick fix for maroon group home page
    article.textBoxTriangleRight {
       .imageBox { 
            z-index: 1;
        }
    }
}