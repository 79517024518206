article.history {
  margin-bottom: 50px;
  .verticalBreak {
    border-right: 1px solid #333;
  }
  h1 {
    margin-bottom: 100px;
    @include breakpoint(small-medium) {
      margin-bottom: 20px;
    }
  }

  article.standardTextImageBox {
    margin-top: -50px;
    margin-bottom:0;
    @include breakpoint(small-medium) {
      margin-top: 20px;
      width: 90%;
    }
    .imageBox {
      margin-bottom: 0;
      padding: 0;
      img {
        max-height: 200px;
        width: auto;
      }
    }
  }
  .timeline:after {
    content:"";
    position: absolute;
    z-index: 1;
    top: 120px;
    bottom: 0;
    left: 50%;
    border-left: 1px solid $lightGray;
    @include breakpoint(small-medium) {
      border: none;
    }
  }
}
