article.socialShare {
  background-color: $maroon;
  width: 100%;
  max-width: 150px;
  padding: 15px;
  h4 {
    color: $white;
    margin: 0;
    text-transform: none;
  }
  .socialIcons{
    a {
      color: $white;
      font-size: 25px;
      margin-right: 15px;
      &:hover {
        text-decoration: none;
      }
      &:focus {
          text-decoration: none;
      }
    }
  }
}
