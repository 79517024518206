article.searchBox {
  height: 300px;
  background: rgba(68, 68, 68, 0.95);
  position: absolute;
  top: 400px;
  width: 100%;
  z-index: 202;

  .input-group-btn {
    //position: static;
    z-index: 3;
    position: absolute;
    right: 0;
  }

  &.fix-search {
    position: fixed;
    height: 105px;
    margin-top: 0;
    top: 0;
    width: 100%;
    left: 50% !important;
    -webkit-transform: translate(-50%, 0) !important;
    -ms-transform: translate(-50%, 0) !important;
    transform: translate(-50%, 0) !important;

    h2 {
      display: none;
    }


    #custom-search-input {
      margin-top: 0;
    }

    #custom-search-input .search-query {
      height: auto;
      font-size: 16px;
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid $white;
    }

    #custom-search-input button {
      top: 0px;
      font-size: 18px;
    }

    .col-md-12 {
      padding-bottom: 0;
    }

    .btn-white {
      font-size: 18px;
      border: none;
      margin-top: 0;
      padding-top: 0;
    }

    .btn-white:after {
      font-size: 24px;
    }

    .btn-white:hover {
      background-color: transparent;
      color: #ABABAB;
    }

    .dropdownMenu {
      top: -10px;
    }

    .dropdownTypeAhead {
      top: 40px;
    }
  }

  .input-group {
    width: 100%;
    position: relative;
  }

  .row {
    margin: 0;

    .col-md-12 {
      padding: 15px 0;
    }
  }

  h2 {
    color: $white;
    margin-top: 30px;
    margin-bottom: 0;

    @media only screen and (min-width: 769px) and (max-width: 992px) {
      margin-top: 10px;
    }
  }

  #custom-search-input {
    margin: 0;
    padding: 0;
  }

  .platformMarginContainer {
    margin-top: 30px;
  }

  .platformMarginContainer #custom-search-input .input-group .input-group-btn button {
    border: 0;
    background: none;
    padding: 2px 2px;
    margin-top: 2px;
    position: absolute;
    right: 15px;
    top: 10px;
    margin-bottom: 0;
    border-radius: 0px;
    font-size: 24px;
    color: #FFFFFF;
  }

  #custom-search-input .search-query {
    background-color: $textGray;
    border: none;
    width: 100%;
    border-bottom: 2px solid $white;
    font-size: 20px;
    padding: 5px 20px;

    @include breakpoint (small) {
      padding: 5px 42px 5px 20px;
    }

    margin-bottom: 0;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;

    &::placeholder {
      color: $lightGray;
    }

    &::-ms-clear {
      display: none;
    }
  }

  .form-control::-webkit-input-placeholder {
    color: $white;
  }

  .form-control {
    color: $white;
  }

  #custom-search-input button {
    border: 0;
    background: none;
    /** belows styles are working good */
    padding: 2px 2px;
    margin-top: 2px;
    position: absolute;
    right: 30px;
    top: 5px;
    /* IE7-8 doesn't have border-radius, so don't indent the padding */
    margin-bottom: 0;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    font-size: 34px;
    color: $white;
  }

  .search-query:focus + button {
    z-index: 3;
  }


  .first {
    padding-left: 0;
  }

  .btn-white {
    width: 100%;
    margin-top: 20px;

    @include breakpoint(small) {
      padding-left: 10px;
    }

    @media only screen and (min-width: 769px) and (max-width: 992px) {
      margin-top: 0;
    }
  }

  .btn-white:after {
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
    display: inline-block;
    position: relative;
    top: -2px;
    width: 8px;
    height: 8px;
    margin-left: 5px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
  }

  .btn-white:hover {
    background-color: $white;
    color: $textGray;
  }
  /*@media (min-width: 992px) {
    .dropdownSearch:hover {
      .dropdownMenu {
        display: block;
      }
    }
  }*/
  .dropdownMenu {
    //display: none;
    background-color: $darkGray;
    border: solid 2px $white;
    position: relative;
    top: 7px;
    padding: 20px;

    &.induxtryddMenu {
      overflow: scroll;
      max-height: 600px;
    }

    ul {
      li {
        list-style: none;

        a {
          color: $white;
        }

        a:hover {
          color: $lightGray;
          text-decoration: none;
        }
      }
    }
  }

  .dropdownMenu:before {
    position: absolute;
    top: -8px;
    left: 50%;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ffffff;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }

  .dropdownMenu:after {
    position: absolute;
    top: -7px;
    left: 50%;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: '';
  }

  .supplierDropdownMenu {
    //display: none;
    background-color: $darkGray;
    border: solid 2px $white;
    position: relative;
    top: -7px;
    padding: 20px;
    height: 600px;
    overflow: scroll;

    @include breakpoint (small) {
      padding: 0;
    }

    ul {
      li {
        list-style: none;

        a {
          color: $white;
        }

        a:hover {
          color: $lightGray;
          text-decoration: none;
        }
      }
    }
  }

  .supplierDropdownMenu:before {
    position: absolute;
    top: -8px;
    right: 17%;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ffffff;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }

  .supplierDropdownMenu:after {
    position: absolute;
    top: -7px;
    right: 17%;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: '';
  }

  article.supplierContainer {
    width: 100%;
    padding: 50px 20px;
    z-index: 2;
    position: relative;

    @include breakpoint (small-medium) {
      padding: 20px;

      .logoContainer {
        height: 80px;
        width: 80px;
      }
    }

    @include breakpoint (small) {
      padding: 0;
    }

    .logoContainer {
      background-size: contain !important;
      height: 125px;
      width: 125px;

      @include breakpoint (small) {
        max-height: 80px;
        max-width: 80px;
      }
    }

    .supplierList {
      margin: 30px 10px;

      @include breakpoint (small-medium) {
        margin: 0 10px;
      }
    }

    .col-md-6 {
      padding: 0 20px !important;

      @include breakpoint (small) {
        padding: 10px 0 !important;
      }

      .row {
        margin-bottom: 20px;
      }
    }

    a.t2 {
      color: $white;
    }
  }

  .searchContainer {
    position: relative;
  }

  .dropdownTypeAhead {
    display: none;
    width: 100%;
    height: 340px;
    position: absolute;
    background-color: $darkGray;
    border: solid 2px $white;
    top: 70px;
    padding: 20px;
    z-index: 200;
    overflow: auto;

    p.t2 {
      text-transform: uppercase;
      color: $white;
      margin-bottom: 0;
      line-height: 38px;
    }

    ul {
      margin-bottom: 0;

      li {
        list-style: none;

        a {
          color: $white;
          vertical-align: top;
        }

        a:hover {
          color: $lightGray;
          text-decoration: none;
        }
      }

      li.selected {
        opacity: .5
      }
    }
  }

  span.grey {
    color: #999999;
    font-weight: 600;
  }
}
.pageBody {
  &.topMargin {
    margin-top: 0;
  }
}
article.heroBannerHome {
  &.bottomMargin {
    margin-bottom: 30px;
    margin-top: -200px;
  }
}

@include breakpoint(small) {

  article.searchBox {
    position: relative;
    top: 0;
    z-index: 202;
    height: auto;
    padding-bottom: 50px;
    &.fix-search  {
      position: relative;
      height: auto;
    }
    .row {
      margin: 0;
      .col-md-12 {
        padding: 15px;
      }
    }
    .btn-white {
      margin: 0;
      text-align: left;
    }
    .dropdownSearch {
      .dropdownMenu {
        //display: none;
        .btn-white {
          background-color: transparent;
          color: $white;
          border: none;
        }
      }
      &.active {
        .btn-white {
          background-color: $white;
          color: $textGray;
          border: none;
        }
        .btn-white:after {
          border-color: #000;
        }
        .dropdownMenu {
          //display: block;
          ul {
            padding-left: 30px;
            padding-bottom: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
    .btn-white:after {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .dropdownMenu:before {
      display: none;
    }

    .dropdownMenu:after {
      display: none;
    }
    .dropdownMenu {

      background-color: $white;
      border: none;
      position: relative;
      top: 0px;
      padding: 0px;
      width: 100%;
      z-index: 2;
      ul {
        li {
          list-style: none;
          a {
            color: $textGray;
          }
          a:hover {
            color: $lightGray;
            text-decoration: none;
          }
        }
      }
    }
    .dropdownTypeAhead {
      padding: 10px;
      ul {
        padding-left: 0;
      }
    }

  }

}

@media (max-width: $large) {
  article.platformSearchBox {
    height: auto;
  }
  }

article.platformSearchBox {
  position: relative;
  top: 0;
  z-index: 15;


  h2 {
    color: #FFFFFF;
    margin-top: 50px;
    margin-bottom: 0;
    font: 24px/30px Montserrat, Arial, sans-serif;
    font-weight: 600;
  }

  .platformSearchBoxContainer {
    background-color: $sky;
    text-align: center;
    padding: 15px 40px 35px;
    margin: 50px 0 10px;
  }

  p.t4 {
    text-transform: uppercase;
    font: 15px "Open Sans", Arial, sans-serif;
    color: $textGray;
    line-height: 38px;
  }

  .input-group {
    width: 100%;
    position: relative;
  }

  .input-group-btn {
    z-index: 3;
    position: absolute;
    right: 0;
  }

  #custom-search-input {
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
  }

  .searchContainer {
    background-color: #F7F6F6;
    border-bottom: 1px solid #fff;
  }

  #custom-search-input .search-query {
    border: none;
    width: 100%;
    border-bottom: 0;
    font-size: 21px;
    padding: 5px 20px;

    @include breakpoint (small) {
      padding: 5px 42px 5px 20px;
      font-size: 20px;
    }

    margin-bottom: 0;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;

    &::placeholder {
      color: $lightGray;
    }

    &::-ms-clear {
      display: none;
    }
  }

  #custom-search-input button {
    border: 0;
    background: none;
    padding: 2px 2px;
    margin-top: 2px;
    position: absolute;
    right: 15px;
    top: 13px;
    margin-bottom: 0;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    font-size: 24px;
    color: #ffffff;
  }

  .search-query:focus + button {
    z-index: 3;
  }

  .form-control::-webkit-input-placeholder {
    color: #fff;
  }

  .form-control {
    color: #fff;
  }

  .dropdownSearch .btn.btn-white {
    font: 13px $primaryFont;
    margin-top: 0;
  }

  .last {
    padding-right: 0;
  }
}
