article.verticalListing {
    
    .verticalFilter {
        padding: 50px 0 40px 0;

          .title {
              font-family: $primaryFont;
              font-weight: 400;
              font-size: 28px;
              text-transform: capitalize; 
          }

          .verticalSearchContainer {

               .input-group {  
                    width: 100%;
                    position: relative;
                    padding: 10px 35px 20px 30px;
                    margin: 15px 0;
                    background-color: #F5F6F8;

                     .search-query {
                        background-color: transparent;
                        border: none;
                        width: 100%;
                        font-size: 22px;
                        padding: 5px 0 0;
                        margin-bottom: 0;
                        border-radius: 0px;
                        color: $textGray;
                        border-bottom: 3px solid #666666;
                        box-shadow: none;
                        height: 55px;

                         @include breakpoint (small) {
                          padding: 5px 26px 5px 0px;
                          font-size: 20px;
                        }
                    }

                     input[type="text"] {
                      &::placeholder {
                        color: $textGray;
                        background-color: transparent;
                      }
                    }

                     .input-group-btn {
                        width: 0;
                        z-index: 3;
                        position: absolute;
                        right: 0;

                        button {
                            border: 0;
                            background: none;
                            padding: 2px 2px;
                            margin-top: 2px;
                            position: absolute;
                            right: 35px;
                            top: 10px;
                            margin-bottom: 0;
                            border-radius: 0px;
                            font-size: 24px;
                            color: #FFFFFF;

                            i {
                                color: #333333;
                            }
                        }
                    }
              }
          }
          
      }
    .specs {
        display: block;
        padding-left:0px;

        .specBlock {
            display: inline-block;
            background-color: $maroon;
            padding: 6px 12px;
            margin-right: 10px;
            margin-bottom: 20px;

            p.red-pill {
                font: 14px/24px $primaryFont;
                color:white;
                margin-bottom: 0;

                a {
                    color:white;;
                    padding-left: 10px;
                }
            }
        }
    }

    .verticalList {

        .verticalDescription {
            font: 16px/26px $primaryFont;
            color: $textGray;
            margin-bottom:10px;
            display:block;
        }
    }

    .topBar {
        display: block;
        margin-bottom: 20px;
        padding-left: 20px;

        .dropdownMenu {
            display: none;
            background-color: $white;
            border: solid 2px rgba(0, 0, 0, 0.2);
            position: absolute;
            top: 35px;
            padding: 20px;
            z-index: 999;

            ul {
                padding-left: 0;

                li {
                    list-style: none;

                    a {
                        color: $textGray;
                    }

                    a:hover {
                        color: $textGray;
                        text-decoration: none;
                    }

                    a:after {
                        border: none;
                    }
                }
            }
        }

        .dropdownMenu:before {
            position: absolute;
            top: -8px;
            left: calc(50% - 8px);
            display: inline-block;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #ffffff;
            border-left: 8px solid transparent;
            border-bottom-color: rgba(0, 0, 0, 0.2);
            content: '';
        }

        .dropdownMenu:after {
            position: absolute;
            top: -6px;
            left: calc(50% - 6px);
            display: inline-block;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #ffffff;
            border-left: 6px solid transparent;
            content: '';
        }

        .dropdownFilter {
            display: inline-block;
            background: #fff;
        }

        .dropdownFilter:hover {
            .dropdownMenu {
                display: block;
            }
        }

        .results {
            font-family: $primaryFont;
            font-weight: 600;
            font-size: 20px;
            text-transform: uppercase;
            padding-right: 20px;
            display: inline;
        }

        .sorting {
            display: inline;

            a {
                padding-right: 15px;
                padding-left: 10px;
                position: relative;
            }

            a:after {
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                content: "";
                display: inline-block;
                position: relative;
                top: -4px;
                right: -5px;
                width: 8px;
                height: 8px;
                margin-left: 5px;
                border: solid $maroon;
                border-width: 0 1px 1px 0;
            }
        }
    }

    .verticalList {
        display: flex;
        flex-flow: row wrap;
        @include breakpoint(small) {
            display: block;
        }

        .verticalCard {
          flex-grow: 1;
            flex-shrink: 1;
            flex: 1;
            flex-basis: 100%;
            background-color: #F5F6F8;
            margin:15px 1px;
            position:relative;

            .verticalNameLogo {
                position: relative;
                display: block;
                min-height:200px;
            }

            .verticalLogo {
                text-align: center;

                h3 {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    text-align: center;
                    transform: translate(-50%, -50%);
                    text-transform: uppercase;
                    width: 100%;
                    margin: 0;
                    padding: 20px 15px;
                    z-index: 2;
                    font: 24px/32px $primaryFont;
                    color:white;
                }

                img {
                   min-height:200px;
                   width: 100%;
                   height: auto;
                   max-height: 240px;
                }

                &:hover::after {
                    opacity: .5;
                }

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #000000;
                    opacity: .3;
                    content: '';
                }
            }

            .resultsText {
                padding: 30px 20px;
                display: block;
                font: 14px/24px $primaryFont;
                color: $textGray;
                text-decoration: underline;
                border: 1px solid black;
            }
        }
    }

    @media (max-width: 992px) {
        .specs {
            padding-left: 0px;
            margin-top: 20px;

            .specBlock {
                
            }
        }
    }
}
