article.browseLeft {
position: relative;
  padding: 60px 20px;
  line-height: 0;
  a.imageLink {
      font-size: 0;
      line-height: 0;
  }
  .titleBox {
    position: absolute;
    top: -60px;
    left: -20px;
    background-color: $maroon;
    height: 360px;
    width: 360px;
    z-index: 1;
    h4 {
      text-transform: uppercase;
      color: $white;
      margin: 20px;
    }
  }
  .imageContainer {
    display: inline-block;
    position: relative;
    z-index: 2;
    width: 49%;
    padding-top: 27.6%;
    background-size: cover;
   h3 {
      position:absolute;
      top:50%;
      left:50%;
      text-align:center;
      transform: translate(-50%, -50%);
      color: $white;
      text-transform: uppercase;
      font-size: 24px;
      width: 100%;
      margin: 0;
      padding: 20px;
      z-index: 2;
    }
  }

  .imageContainer::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: .3;
    content: '';
  }
  .imageContainer:hover::after {
    opacity: .5;
  }
  article.supplierContainer {
    background-color: $white;
    width: 100%;
    padding: 50px 20px;
    z-index: 2;
    position: relative;
    .logoContainer {
      background-size: contain!important;
      height: 125px;
      width: 125px;
    }
    .supplierList {
      margin: 30px 10px;
      @include breakpoint (small-medium) {
          margin: 0 10px;
      }
    }
    .col-md-6 {
      padding: 0 20px!important;
      .row {
        margin-bottom: 20px;
      }
    }
    a.t2 {
      color: $textGray;
    }
  }
}

@media (max-width: $medium) {
  article.browseLeft {
    padding: 20px 0;
    margin: 50px 0;
    .titleBox {
    top: -50px;
    height: 200px;
    width: 240px;
  }
    .imageContainer {
      h3 {
        font-size: 13px;
      }
    }
    article.supplierContainer {
      padding: 20px;
      .logoContainer {
        height: 80px;
        width: 80px;
      }
    }
  }
}
