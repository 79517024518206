/* Colors */
$textGray: #333333;
$maroon: #A8353A;
$sky: #F5F6F8;
$blueMetal: #5A7D88;
$darkGray: #444444;
$lightGray: #666666;
$subsidiary: #DEE5E7;
$offWhite: #F7F6F6;
$white: #FFFFFF;
$yellow: #FFD842;
$darkMaroon: #3A0312;
$hoverGray: #888888;
$dbBecker: #292563;
$addipel: #000000;
$cnx: #A8353A;
$usChemicals: #A8353A;

/* Responsive Breakpoints */
$small: '414px';
$medium: '768px';
$large: '1200px';
$xlarge: '1440px';

/* Fonts */
$primaryFont: Montserrat, Arial, sans-serif;
$secondaryFont: "Open Sans", Arial, sans-serif;

/* Font Awesome Adjustment */
.fa-times-thin:before {
  content: '\00d7';
}
