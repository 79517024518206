article.fullPageModal {
  .modal {
    padding: 0 !important;
  }
  .modal-xl {
    margin: 0;
  }
}
.modal-sm, .modal-md, .modal-lg, .modal-xl {
  .modal-body {
    padding: 0;
    border: 0!important;
  }
  .modal-content {
    //height: 100%;
    background-color: $sky;
    overflow: hidden;
    padding: 1px 0;
    box-shadow: none;
    border: 0 !important;
    border-radius: 0;
    .closeButton {
      position: relative;
      z-index:200;
      .btn-close {
        border: none;
        color: $textGray;
        font-size: 20px;
        float: right;
      }
    }
  }
}
.modal-xl.modal-dialog {
    margin: 0;
}
.modal-backdrop {
  background-color: $white !important;
}

.modal {
    article.searchBox {
    position: static;
    background-color: transparent;
    border-bottom: 2px solid $lightGray;
    .col-md-12 {
      padding-right: 20px;
    }
    h2 {
      color: $textGray;
    }
    button {
      color: $textGray;
      border-color: $textGray;
      i {
        color: $textGray;
      }
    }
    .btn-white:after {
        border-color: $textGray;
    }
    .btn-white:hover {
      background-color: $textGray;
      color: white;
    }
    .input-group-btn {
      width: 0;
    }
    input[type="text"] {
      &::placeholder {
        color: $textGray;
        background-color: transparent;
      }
    }
    #custom-search-input .search-query {
      background-color: transparent;
    }
    .form-control {
      color: $textGray;
    }
    .searchContainer {
      background-color: $offWhite;
      border-bottom: 2px solid $lightGray;
    }
    .dropdownMenu {
      background-color: $sky;
      border: solid 2px $lightGray;
      ul {
        li {
          a {
            color: $textGray;
          }
          a:hover {
            color: $lightGray;
            text-decoration: none;
          }
        }
      }
    }
    .dropdownMenu:before {
      position: absolute;
      top: -8px;
      left: calc(50% - 8px);
      display: inline-block;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #F5F6F8;
      border-left: 8px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      content: '';
    }

    .dropdownMenu:after {
      position: absolute;
      top: -6px;
      left: calc(50% - 6px);
      display: inline-block;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #F5F6F8;
      border-left: 6px solid transparent;
      content: '';
    }
     .supplierDropdownMenu {
    //display: none;
      background-color: $sky;
      border: solid 2px $lightGray;

        article.supplierContainer {
                a.t2 {
              color: $textGray;
            }
        }
    }
     .supplierDropdownMenu:before {
      top: -8px;
      border-bottom: 7px solid #F5F6F8;
      border-left: 7px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.2);
    }

    .supplierDropdownMenu:after {
      top: -6px;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #F5F6F8;
      border-left: 6px solid transparent;
    }
    .dropdownTypeAhead {
      background-color: $white;
      border: solid 2px $lightGray;

      p.t2 {
        color: $textGray;
      }
      ul {
        li {
          a {
            color: $textGray;
          }
          a:hover {
            color: $lightGray;
            text-decoration: none;
          }
        }
      }
    }
  }
  &.header-search {
    .modal-content {
      overflow: visible;
    }
  }
}