article.bottomHeroBanner {
  //position: relative;
  padding: 120px 0;
  background-color: $offWhite;
  div.container {
    position: relative;
    z-index: 2;
  }
  .img-responsive {
      width: 100%;
      @include breakpoint (small) {
          display: none;
      }
  }
  .statsWrapper .statsCol {
      background: #333;
  }
  .stats {
    margin-top: 40px;
  }

  .mobileSlash {
      .mask {
          polygon {
              display: none;
          }
      }
  }
  
  @include breakpoint(small-medium) {
    .statsWrapper {
      margin-top: 50px;
    }
    .stats {
      margin-top: 0px;
    }
    .mobileSlash {
      position: absolute;
      width: 999px;
      height: 700px;
      bottom: 50px;
      left: 25%;
      .mask {
        width: 999px;
        height: 700px;
        position: absolute;
        bottom: -100px;
        right: 0px;
        polygon {
            display: block;
        }
      }
    }
  }
  @include breakpoint(small) {
    padding: 20px 0;
    .statsWrapper .statsCol {
      background-color: transparent;
      padding: 0;
      width: 100%;
  }
    .mobileSlash {
     right: 0;
     left: 0;
     display: none;
    }
  }

  @include breakpoint(small) {
    article.standardTextBox {
      .btn {
        width: 100%;
      }
    }
  }

}