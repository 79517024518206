article.backgroundSlash {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: -60px;
  width: 100%;
  height: 100%;
  overflow: visible;
  .mask {
    width: 2400px;
    height: 4000px;
    overflow: visible;
  }
}
