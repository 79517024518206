article.platformPage {
  .anchor {
    padding-top: 150px;

    @include breakpoint(small-medium) {
      padding-top: 50px;
    }
  }

  article.background {
    overflow: visible;
    position: absolute;
    top: 0;
    height: 3000px;
    width: 100%;
  }

  .pageBody {
    background-color: transparent;
  }

  article.backgroundSlashLeft {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 2800px;
    overflow: hidden;

    .mask {
      width: 2400px;
      height: 2800px;
      overflow: visible;
    }
  }

  article.backgroundSlashRight {
    position: absolute;
    background-color: transparent;
    top: 2400px;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .mask {
      width: 2400px;
      height: 2200px;
      overflow: visible;
    }
  }

  article.topHeroBanner {

    .textCol {
      background: #ffffff;
    }

    .heroBannerText {
      margin: 60px 0 60px 0;

      h1 {
        font-weight: 600;
        font-size:40px;
      }
    }

    @include breakpoint (small) {
      margin-top: 0px;

      .heroBannerText {
        width: 100%;
      }
    }
  }

  article.regionalCompanies {
    @include breakpoint(small) {
      margin-top: 0;
    }

    .linkContainer {
      background-color: $sky;
      text-align: center;
      padding: 50px 30px;

      p.t4 {
        text-transform: uppercase;
      }

      .companyLinks a {

        img.bottom {
          position: relative;
          width: 100%;
          height: auto;
          opacity: 0;
        }

        &:hover {
          img.bottom {
            opacity: 100;
          }
        }

        img.top {
          position: absolute;
          width: 100%;
          height: auto;
          left: 0;
          top: 0;
          padding: 0 15px;

          @include breakpoint(small-medium) {
            padding: 0;
          }

          &:hover {
            opacity: 0;
          }
        }
      }
    }
  }

  article.staff {
    background-color: transparent;
  }

  .modal-content {
    margin-top: 150px;

    @include breakpoint(small) {
      margin-top: 50px;
    }
  }

  article.ourCompany {
    margin: 60px 0 100px;

    img {
      width: 100%;
      height: auto;
    }

    h2 {
      font-weight: 600;
    }

    @include breakpoint(small-medium) {
      margin-top: 20px;
    }
  }

  article.image {
    margin-top: 50px;
  }

  article.staff, article.industries {
    h2 {
      @include breakpoint(small-medium) {
        margin-left: -15px;
      }
    }
  }

  article.industries {
    margin-bottom: 80px;

    @include breakpoint(small-medium) {
      margin-bottom: 0;
    }

    ul {
      padding-left: 0;
      list-style: none;
      font-size: 16px;
      font: $secondaryFont;
      color: $textGray;

      li {
        margin-bottom: 20px;
      }
    }
  }

  article.textDoubleTriangleRight {
    background-color: transparent;
    margin-bottom: -50px;

    .imageBox {
      position: absolute;
      left: 30%;
    }

    article.standardTextBox {
      margin: 100px 0;

      @include breakpoint(small-medium) {
        margin: 20px 0;
      }

      p.t2 {
        margin-bottom: 30px;
      }
    }
  }

  ul {
    padding-left: 15px;
  }

  article.standardTextBox {
    h2 {
      margin-bottom: 30px;
    }
  }

  article.legacyContainer {
    width: 100%;
    background: no-repeat top center;
    position: relative;
    margin-top: 100px;

    @include breakpoint(small-medium) {
      background-image: none !important;
      margin-top: 0;
    }

    .standardTextBox {
      margin: 80px 0;

      @include breakpoint (small-medium) {
        margin: 20px 0;
      }

      h2, p.t2 {
        color: $white;

        @include breakpoint(small-medium) {
          color: $textGray;
        }
      }
    }

    article.quoteBox {
      margin-top: -60px;
      margin-bottom: -60px;

      @include breakpoint (small-medium) {
        margin: 0;
      }

      p.t3 {
        margin-bottom: 50px;
      }
    }
  }

  .press {
    margin-top: 30px;
    margin-bottom: 50px;

    .standardTextBox {
      text-align: left;
    }

    button {
      margin: 50px;
    }

    .extraPress {
      article.standardCard {
        display: none;
      }
    }
  }

  article.contactUs {
    margin: 30px 0;

    article.standardTextBox {
      margin: 30px 0;
    }

    .contactInfo {
      text-align: left;

      article.standardTextBox {
        text-align: left;

        p.t3 {
          text-transform: none;
        }

        p.t2 {
          color: $maroon;
        }
      }

      &.phoneNumbers {
        position: relative;

        p.t2, a.t2 {
          margin-left: 40px;
        }

        i {
          position: absolute;
          left: 0;
        }

        a {
          line-height: 18px;
        }
      }
    }
  }

  article.footer {
    margin-top: 50px;
    padding: 20px;

    .smallPrint {
      text-align: left;

      @include breakpoint(small) {
        text-align: center;
      }
    }

    .socialMedia {
      margin-top: 10px;
      text-align: right;

      @include breakpoint(small) {
        text-align: center;
      }
    }
  }
}


article.platformPage.platformSearchPage {
  background-color: #fff;

  article.contactUs {
    margin: 150px 0 0;
    background-color: #F5F6F8;
    padding-bottom:50px;
  }

  article.footer {
    margin-top: 0px;
  }
}


@media (max-width: $xlarge) {
  article.topHeroBanner {

    .heroBannerText {
      padding: 0 20px;
    }
  }
}

@media (max-width: $large) {
  article.verticalListings .marginContainer {
    margin: 0;

  }
}


@media screen and (max-width: 950px) {
  article.platformPage .pageBody {
    margin-top: 0;
  }
}

@include breakpoint(small-medium) {
  article.platformPage article.topHeroBanner .textCol {
    background: transparent;
  }

  article.topHeroBanner {
    
    .heroBannerText {
      padding: 0 20px;
    }
  }
}
@include breakpoint(small) { 
  article.platformPage {
    .pageBody {
      margin-top: 62px;
    }

    article.topHeroBanner .textCol {
      background: transparent;
    }

    article.topHeroBanner .heroBannerText {
      margin: 50px 0 10px 0;

      h1 {
        font-size: 24px;
      }
    }

    article.verticalListings {
      padding: 0;
    }

    article.topHeroBanner {
      margin-bottom: 30px;

      .heroBannerText {
        padding: 0;
      }
    }

    article.platformSearchBox {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }
}
