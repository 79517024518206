﻿article.ourCompany {
    padding: 40px 0;
    @include breakpoint (small) {
        padding: 0;
    }
    background-color: $white;
    img {
        max-width: 100%;
        height: auto;
        @include breakpoint(small-medium) {
            width: 100%;
            margin-top: 40px;
        }
    }
    h2 {
        font-weight: 600;
    }
    @include breakpoint(small) {
        .btn {
            width: 100%;
        }
    }
}
