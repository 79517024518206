article.careersDetail {
  margin-top: 50px;
  .title {
    margin: 20px 0;
  }
  article.listingNavigation {
    display: none;
    @include breakpoint(small){
      display: block;
    }
  }
  article.detailNavigation {
    @include  breakpoint(small) {
      display: none;
    }
  }
}
