article.signIn {
  h2 {
    margin-bottom: 30px;
  }
  a {
    display: inline-block;
    margin: 30px 0;
  }
  button {
    width: 100%;
  }
  .register {
    text-align: center;
  }
}
