article.sectionHeader {
  background-color: $sky;
  ul.navigationLinks {
    @include layout-list;
    position: relative;
    float: left;
    font-size: 14px;
    @include breakpoint(small) {
        top: 50px;
    }
    a {
      display: inline-block;
      font-family: $primaryFont;
      color: $maroon;
      font-size: 14px;
      margin: 20px 20px 0 0;
      padding-bottom: 20px;
      @include breakpoint(small) {
        padding-bottom: 0;
    }
      &.active {
        color: $darkMaroon;
        text-decoration: none;
        padding-bottom: 18px;
        border-bottom: 2px solid $darkMaroon;
      }
    }
    a:hover {
      color: $darkMaroon;
      text-decoration: none;
      padding-bottom: 18px;
      border-bottom: 2px solid $darkMaroon;
    }
    li:first-child a {
      margin-left: 0;
    }
  }
}
