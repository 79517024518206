article.quoteBox {
  position: relative;
  width: 100%;
  background-color: $maroon;
  margin-top: 60px;
  padding: 60px;
  @include breakpoint (small-medium) {
      padding: 30px;
  }
  h3 {
    margin-top: 40px;
    color: $white;
    @include breakpoint(small-medium) {
        margin-top: 50px;
    }
  }
  .t3 {
    margin-top: 20px;
    color: $white;
    @include breakpoint(small-medium) {
        font-size: 22px;
    }
  }
}
article.quoteBox:before {
  content: "“";
  font-family: Montserrat, Arial, sans-serif;
  color: $white;
  font-size: 150px;
  position: absolute;
  left: 50px;
  top: -10px;
}
