﻿.loadingMessage {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    z-index: 2;
    span {
        $height: 32px;
        $width: 130px;
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: $width;
        height: $height;
        margin: $height/2*-1 0 0 $width/2*-1;
        text-transform: uppercase;
        font-weight: bold;
        img {
            margin-right: 5px;
            vertical-align: middle;
        }
    }
}